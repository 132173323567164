import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef
} from "react"
import { useInView } from "react-intersection-observer"

export const NavContext = createContext()
const NavContextProvider = NavContext.Provider

export function NavWrapper({ children }) {
  const [inView, setInview] = useState(true)

  function checkIntersection(e) {
    setInview(e.isIntersecting)
  }

  // Some pages want the nav in white for starting. Those are the special ones
  const [isSpecial, setIsSpecial] = useState(false)
  function nonCase() {
    setIsSpecial(false)
    setInview(false)
  }
  function makeSpecial(arg) {
    setIsSpecial(arg)
  }

  return (
    <NavContextProvider
      value={{ inView, makeSpecial, isSpecial, checkIntersection, nonCase }}
    >
      {children}
    </NavContextProvider>
  )
}

//

export function useNav() {
  return useContext(NavContext)
}
