import React from "react"
import { LanguageContextWrapper } from "./LanguageContext"
import { NavWrapper } from "./NavWrapper"

export * from "./LanguageContext"
export * from "./NavWrapper"

export function ContextWrapper({ children }) {
  return (
    <LanguageContextWrapper>
      <NavWrapper>{children}</NavWrapper>
    </LanguageContextWrapper>
  )
}
