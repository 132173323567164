import React, { createContext, useContext, useState } from "react"

const initialState = {
  language: "de",
  updateLanguage(lang) {}
}

const LanguageContext = createContext(initialState)
const { Provider } = LanguageContext

export const LanguageContextWrapper = ({ children }) => {
  const [language, setLanguage] = useState("de")

  function updateLanguage(lang) {
    setLanguage(lang)
  }
  return (
    <Provider value={{ ...initialState, language, updateLanguage }}>
      {children}
    </Provider>
  )
}

export function useLanguage() {
  return useContext(LanguageContext)
}

export default LanguageContext
